<template>
  <Modal :visible="visible">
    <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs">
      <div class="mb-8 flex justify-between space-x-3">
        <h3>Edit Code</h3>
        <button class="px-2 text-xl text-green" @click="close">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div v-if="error" class="flex space-x-5 mb-4">
        <p class="text-red ml-1">{{ error }}</p>
      </div>
      <div v-if="code" class="grid grid-cols-1 gap-4 mb-10">
        <div class="col-span-1">
          <label class="block text-xs font-body text-gray-dark mb-2 ml-1">*Key:</label>
          <input
            v-model="$v.code.key.$model"
            type="text"
            class="cp-input mb-0"
            :class="{ 'cp-input-error': $v.code.key.$error }"
          />
          <template v-if="$v.code.key.$error">
            <p v-if="!$v.code.key.required" class="text-red text-xs ml-1">
              Key is required
            </p>
            <p v-if="!$v.code.key.alphaNum" class="text-red text-xs ml-1">
              Key accepts only alpha numeric characters
            </p>
          </template>
        </div>
        <div class="col-span-1">
          <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Status:</label>
          <Checkbox v-model="$v.code.enabled.$model" label="Enabled" />
          <Checkbox v-model="$v.code.sitewide.$model" label="Sitewide" />
          <Checkbox v-model="$v.code.affiliate.$model" label="Affiliate" />
        </div>
        <div class="col-span-1">
          <div class="flex">
            <DateRangePicker
              class="mr-2"
              label="Dates:"
              :time="true"
              :value="dateRange"
              :default="defaultDateRange"
              @input="dateRangeChanged"
            />
          </div>
        </div>
        <div class="col-span-1">
          <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
            >Max Uses:</label
          >
          <input
            v-model="$v.code.max_uses.$model"
            type="number"
            class="cp-input mb-0"
            :class="{ 'cp-input-error': $v.code.max_uses.$error }"
          />
          <template v-if="$v.code.max_uses.$error"> </template>
        </div>
        <div class="col-span-1">
          <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
            >Max Uses Per User:</label
          >
          <input
            v-model="$v.code.max_uses_per_user.$model"
            type="number"
            class="cp-input mb-0"
            :class="{ 'cp-input-error': $v.code.max_uses_per_user.$error }"
          />
          <template v-if="$v.code.max_uses_per_user.$error"> </template>
        </div>
        <div class="col-span-1">
          <label class="block text-xs font-body text-gray-dark mb-2 ml-1">
            Allowed Fulfillment:
          </label>
          <multiselect 
            class="max-w-xs ml-1" 
            v-model="code.fulfillment_ids"
            :options="fulfillmentTypes"
            :multiple="true"
            :close-on-select="false"
            :show-labels="false" 
            :allow-empty="false"
            label="name" 
            track-by="id"
            placeholder="Select fulfillment type">
            <template slot="placeholder">
              <span class="text-gray-400">Select fulfillment types</span>
            </template>

            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <span class="absolute right-3 top-3 select-none text-green">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="col-span-1">
          <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Show in Registration Notes:</label>
          <Checkbox v-model="$v.code.show_in_notes.$model" label="Show" />
          <label class="block text-xs font-body text-gray-dark mb-2 ml-1 mt-2">Usable for gift cards:</label>
          <Checkbox v-model="$v.code.usable_for_gift_cards.$model" label="Can use in gift card purchases" />
        </div>
        <div class="col-span-1">
          <Textbox
            v-model="$v.code.description.$model"
            :class="{ 'cp-input-error': $v.code.description.$error }"
            label="Description:"
            placeholder=""
          />
          <template v-if="$v.code.description.$error"> </template>
        </div>
      </div>
      <div class="flex space-x-5">
        <button class="btn white px-6" @click="close" :disabled="saving">Cancel</button>
        <button
          class="btn button-green px-6"
          @click="saveCode"
          :disabled="!canUpdateCodes || saving"
        >
          <div v-if="saving" class="flex items-center space-x-1">
            <Loader class="w-5 h-5" /><span>Saving</span>
          </div>
          <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Saved!</span>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { required, alphaNum } from 'vuelidate/lib/validators'
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'
import DateRangePicker from '@components/global/dates/DateRangePicker'
import Textbox from '@components/global/forms/Textbox'
import Radio from '@components/global/forms/Radio'
import Checkbox from '@components/global/forms/Checkbox'
import { DateTime } from 'luxon'

export default {
  name: 'EditCodeModal',

  components: {
    Loader,
    Modal,
    Radio,
    Textbox,
    DateRangePicker,
    Checkbox,
  },

  props: {
    value: {
      type: Object | null,
      required: true,
      default: {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      saving: false,
      success: false,
      type: null,
      dateRange: this.getDefaultDateRange(),
      defaultDateRange: this.getDefaultDateRange(),
      code: this.getCodesDefault(),
      fulfillmentTypes: [
        { id: 1, name: 'In-person Preparation Class', key: 'in-person' },
        { id: 2, name: 'Pre-assembled Meals', key: 'pre-assembled' },
        { id: 3, name: 'Delivery', key: 'delivery' },
        { id: 4, name: 'Shipping', key: 'shipping' },
      ],
    }
  },

  methods: {
    close() {
      this.error = null
      this.codes = this.getCodesDefault()
      this.$emit('close')
    },
    update() {
      this.$emit('update')
    },
    saveCode() {
      if (this.code.start) this.code.start = this.code.start.toUTC()
      if (this.code.end) this.code.end = this.code.end.toUTC()

      this.error = null
      this.saving = true
      this.$app.services.promotion
        .updatePromotionCode(this.code.id, this.code)
        .then((response) => {
          this.success = true
          setTimeout(() => {
            this.success = false
            this.close()
            this.$emit('update')
          }, 1000)
        })
        .catch((error) => {
          this.error = error?.response?.data?.message
        })
        .finally(() => {
          this.saving = false
        })
    },
    dateRangeChanged(input) {
      const range = input.range
      this.dateRange = range
      this.code.start = range.start
      this.code.end = range.end
    },
    getDefaultDateRange() {
      return { start: null, end: null }
    },
    getCodesDefault() {
      return {
        type: null,
        max_uses: null,
        max_uses_per_user: null,
        enabled: true,
        sitewide: false,
        affiliate: false,
        show_in_notes: false,
        usable_for_gift_cards: false,
        description: null,
        dateRange: this.getDefaultDateRange(),
        key: null,
        fulfillment_ids: [],
      }
    },
    handleFulfillmentChange(selected) {
      console.log('Selected Fulfillments:', selected);
    },
  },

  computed: {
    canUpdateCodes() {
      return this.isValid && this.hasPermissions
    },
    hasPermissions() {
      return this.$app.services.auth.hasPermission('promotions.codes.create')
    },
    isValid() {
      return !this.$v.code.$invalid
    },
  },

  created() {
    this.code = JSON.parse(JSON.stringify(this.value)) || this.getCodesDefault();
    this.code.fulfillment_ids = this.code.fulfillments.map((fulfillment) => {
      return this.fulfillmentTypes.find((type) => type.id === fulfillment.id);
    });
    
    if (this.value?.start) this.code.start = DateTime.fromISO(this.value.start);
    if (this.value?.end) this.code.end = DateTime.fromISO(this.value.end);

    this.dateRange = {
        start: this.value?.start ? DateTime.fromISO(this.value.start) : null,
        end: this.value?.end ? DateTime.fromISO(this.value.end) : null,
    };
  },

  validations: {
    code: {
      start_date: {},
      expiration_date: {},
      max_uses: {},
      max_uses_per_user: {},
      enabled: {},
      sitewide: {},
      affiliate: {},
      show_in_notes: {},
      usable_for_gift_cards: {},
      description: {},
      key: { required },
      fulfillment_ids: {},
    },
  },
}
</script>

<style scoped></style>
